.table {
  .table-row:hover {
    .cmp_name_id {
      .cmp_name {
        color: var(--app-primary);
      }
    }
  }
}

.table-wrapper .table .table-body .table-row .action_options {
  justify-content: end;
}


//Mat Sticky Table CSS
.table-container {
  // max-height: calc(100vh - 280px);
  height: 100%;
  overflow: auto;
  border: 1px solid var(--app-border-3) !important;
  border-radius: var(--table-border-radius);
}

.mat-mdc-header-cell,
.mat-mdc-header-cell.mat-mdc-table-sticky {
  background: var(--table-head-bg) !important;
  opacity: 1;
  color: var(--app-white) !important;
}

.mdc-data-table__cell, .mdc-data-table__header-cell {
  padding: 0 12px !important;
}

.mat-mdc-table-sticky {
  flex: 1 0 10%;
  opacity: 1;
  color: var(--app-text-color-dark);
}

.mat-mdc-table .mdc-data-table__header-row {
  z-index: 9 !important;
}

.mat-mdc-table {
  border: 1px solid var(--bs-table-border-color) !important;

  .mat-mdc-header-row{
    .mdc-checkbox .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate=true])~.mdc-checkbox__background {
      border-color: var(--app-white);
    }
  
    .mdc-checkbox .mdc-checkbox__native-control:enabled~.mdc-checkbox__background .mdc-checkbox__mixedmark {
      border-color: var(--app-white) !important;
    }
  }

  mat-cell:first-of-type, mat-header-cell:first-of-type, mat-footer-cell:first-of-type{
    padding-left: 12px;
  }
}

.mat-column-filler {
  padding: 0 8px;
  font-size: 10px;
  text-align: center;
}

.mat-mdc-header-cell,
.mat-mdc-cell {
  min-width: 30px;
  box-sizing: border-box;
}

.mat-mdc-header-row,
.mat-mdc-row {
  min-width: 1700px;
  /* 24 columns, 80px each */
}

// .mat-mdc-cell.mat-mdc-table-sticky:nth-child(odd),
.mat-mdc-row:nth-child(odd) {
  background: var(--fc-background) !important;
}

.mat-mdc-table-sticky-border-elem-top {
  border-bottom: 0px solid midnightblue;
}

.mat-mdc-table-sticky-border-elem-right {
  border-left: 1px solid #ddd;
}

.mat-mdc-table-sticky-border-elem-bottom {
  border-top: 1px solid midnightblue;
}

.mat-mdc-table-sticky-border-elem-left {
  border-right: 1px solid #ddd;
}

.mat-column-name {
  word-wrap: break-word !important;
  white-space: unset !important;
  flex: 0 0 20% !important;
  width: 20% !important;
  overflow-wrap: break-word;
  // box-shadow: 0px 3px 5px 0px #00000082;
  border-right: 1px solid #ddd;
}

// .mat-column-actions {
//   // flex: 1rem;
//   // width: 15% !important;
//   word-wrap: break-word !important;
//   white-space: unset !important;
//   flex: 0 0 6.5% !important;
//   width: 6.5% !important;
//   overflow-wrap: break-word;
//   word-wrap: break-word;
//   // box-shadow: 0px 3px 5px 0px #00000082;
//   border-left: 1px solid #ddd;
// }

// .mdc-data-table__header-cell:last-child {
//   padding: 0 0px 0 0px !important;
//   display: flex;
//   align-items: center;
//   justify-content: center;
// }

.mat-mdc-header-cell {
  .mdc-checkbox {
    .mdc-checkbox__background {
      border-color: var(--app-white) !important;
    }
  }
}