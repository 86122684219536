@import "/src/styles.scss";
@import "/src/assets/mat-table.scss";

.skeletonloader-wapper {
  .wrapper {
    font-size: 8px !important;
  }
}

textarea {
  border-radius: var(--border-radius) !important;
}

svg {
  fill: var(--app-primary);
  // filter: invert(19%) sepia(84%) saturate(1721%) hue-rotate(266deg)
  //   brightness(94%) contrast(97%);
}

.hide_select_arrow .ng-arrow-wrapper{
  display: none;
}

.map-container {
  height: 250px !important;
  width: 100% !important;
  border-radius: var(--card-border-radius) !important;
}

.mat-mdc-menu-panel {
  border-radius: var(--card-border-radius) !important;
  border: var(--card-border) !important;
}

.mat-mdc-menu-item {
  padding: 5px 10px !important;
}

#headerMain>#header {
  box-shadow: var(--fc-box-shadow);
}

.bi-upload::before {
  font-weight: bolder !important;
}


.ng-option-disabled {
  pointer-events: none;
  cursor: not-allowed !important;
}

.gd_icon {
  font-size: 1.2em;
  aspect-ratio: 1/1;

  &::before {
    vertical-align: -00.2em !important;
    background: var(--app-gradient);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

.n_icon {
  font-size: 1.25rem;
  color: var(--app-text-color-dark);
}

.w_icon {
  &:not(:disabled):hover {
    background: var(--app-gradient);
    color: var(--app-white);
  }
}

.mat-mdc-tooltip {
  border-radius: var(--app-border-radius) !important;
}

.mat-mdc-tooltip .mdc-tooltip__surface {
  background-color: var(--app-secondary) !important;
  padding: 0px !important;
  scrollbar-color: transparent transparent;

  &::-webkit-scrollbar {
    width: 0.25rem;
  }

  &::-webkit-scrollbar-thumb {
    background-color: white;
    border-radius: 20px;
  }

  &::-webkit-scrollbar-track,
  &::-webkit-scrollbar-thumb
  {
    visibility: hidden;
  }
  &:hover {
    scrollbar-color: initial;
   
    &::-webkit-scrollbar-track,
    &::-webkit-scrollbar-thumb {
      visibility: visible;
    }
  }
}

.invalid-input,
.invalid-input:focus {
  border-color: var(--app-danger);
}


@media screen and (min-width:1200px)
{
  .campaign_preview,
  .template_preview {
    .preview_inside {
      position: fixed !important;
    }
  }
}

.welcome_title {
  font-size: 30px;
  font-weight: var(--app-fw-9);
}

.welcome_sub_title {
  font-size: 1.5rem;
  font-weight: 400;
  text-transform: capitalize;
}

.avatar-uploader {
  .avatar_icon {
    display: flex;
    background: var(--app-gradient);
    color: var(--app-white);
    padding: 0.5rem;
    border-radius: 50%;
    position: absolute;
    right: 0;
    bottom: 0;
    cursor: pointer;
  }
}

.business_avatar {
  position: relative;

  .active_status {
    position: absolute;
    left: 10%;
  }
}

.skeleton_loader {
  img {
    top: 40vh !important;
    width: 60px !important;
    height: 60px !important;
    opacity: 0.6 !important;
  }
}

// Page/Event Loader
.loader {
  position: absolute;
  left: 0;
  right: 0;
  width: 100px;
  height: 20px;
  z-index: 999;
  bottom: 0;
  top: 0;
  margin: auto;
}

.loader:before,
.loader:after {
  content: "";
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: var(--app-text-color-light);
  box-shadow: 32px 0 var(--app-text-color-light);
  left: 0;
  top: 0;
  animation: ballMoveX 1s linear infinite;
}

.loader:after {
  box-shadow: none;
  transform: translateX(64px) scale(1);
  z-index: 2;
  animation: none;
  animation: trfLoader 1s linear infinite;
}

@keyframes trfLoader {

  0%,
  5% {
    transform: translateX(64px) scale(1);
    background: var(--app-text-color-light);
  }

  10% {
    transform: translateX(64px) scale(1);
    background: var(--app-primary);
  }

  40% {
    transform: translateX(32px) scale(1.5);
    background: var(--app-primary);
  }

  90%,
  95% {
    transform: translateX(0px) scale(1);
    background: var(--app-primary);
  }

  100% {
    transform: translateX(0px) scale(1);
    background: var(--app-text-color-light);
  }
}

@keyframes ballMoveX {

  0%,
  10% {
    transform: translateX(0);
  }

  90%,
  100% {
    transform: translateX(32px);
  }
}
// END Page/Event Loader

.spinner_load {
  top: 10% !important;
}

.spinner-overlay {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  background-color: rgba(19, 33, 68, 0.25);
  // background-color: rgb(213 213 213 / 0%);
}

.card-sticky {
  position: sticky;
  top: 5.3em;
  z-index: 99;
  transition: ease-out top 5s;
}

// Date Range selector
.ngb-dp-header {
  padding: 10px 0 !important;
}

.ngb-dp-arrow {
  display: flex;
  align-items: center;
}

.ngb-dp-weekday {
  color: var(--app-primary) !important;
  font-weight: bold;
}

.ngb-dp-day {
  margin: 1px;
}

.ngb-dp-today .custom-day {
  background-color: rgba(var(--app-secondary-rgb), 0.2) !important;
  border-radius: var(--fc-border-radius);
}


.edit_option {
  cursor: pointer;
  font-size: 0.2em;
  background: rgba(var(--app-blue-rgb), 0.1) !important;

  &:hover {
    background: rgba(var(--app-blue-rgb), 0.3) !important;
    box-shadow: var(--app-box-shadow) !important;
  }
}

.btn-option {
  font-size: 0.8em;
  color: var(--app-text-color-light);
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  line-height: 0;
  padding: 14px;

  &:hover {
    background-color: rgba(52, 143, 251, 0.15);
  }

  &:active {
    border: 0;
    background-color: rgba(52, 143, 251, 0.15) !important;
    color: var(--app-text-color-light) !important;
  }

  .bi::before {
    font-weight: bold !important;
  }
}

.ch_item {
  align-items: center;
  display: flex;
  padding: 10px;
  gap: 5px;
  flex: 1 0 0;
  min-height: 65px;
  border-radius: var(--fc-border-radius);
  background: var(--fc-background);
  box-shadow: var(--fc-box-shadow);

  &.disabled {
    opacity: 0.5;
    pointer-events: auto;
    cursor: not-allowed;

    .form-check {
      pointer-events: none;
    }
  }

  img {
    width: 15%;
  }
}

.channel_sec,
.bsp_sec {
  .slct_item {
    flex-direction: row;
    align-items: center;
    display: flex;
    padding: 15px;
    flex: 1 0 0;
    min-height: 65px;
    border-radius: var(--fc-border-radius);
    background: var(--app-white);
    box-shadow: 0px 0px 8px 0px var(--app-box-shadow);

    &.disabled {
      opacity: 0.5;
      pointer-events: none;
      cursor: not-allowed;
    }
  }
}

.ba_channel {
  .ng-select-container {
    background: var(--app-gradient) !important;
    border: none;

    .ng-value-container {
      color: var(--app-white) !important;

      .ng-placeholder {
        color: var(--app-white) !important;
      }
    }
  }

  .ng-select .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
    display: flex;
    align-items: center;
    gap: 0.5em;
  }

  img {
    width: 15%;
  }

  .ng-arrow-wrapper .ng-arrow,
  .ng-arrow-wrapper .ng-arrow:hover {
    border-color: var(--app-white) transparent transparent !important;
  }

  &.ng-select-opened>.ng-select-container .ng-arrow,
  &.ng-select-opened>.ng-select-container .ng-arrow:hover {
    border-color: transparent transparent var(--app-white) !important;
  }
}

.mat-mdc-menu-content,
.mat-mdc-menu-content .mat-mdc-menu-item .mdc-list-item__primary-text {
  font-family: var(--app-font-family) !important;
  line-height: var(--app-body-line-height) !important;
  font-size: var(--app-body-font-size) !important;
  letter-spacing: var(--app-body-letter-spacing) !important;
  font-weight: var(--app-body-font-weight) !important;
}

// Toggle Slider CSS
.slider {
  .mdc-form-field {
    label {
      display: none !important;
    }
  }

  &.mat-mdc-slide-toggle {
    .mdc-switch {
      width: 3rem;

      .mdc-switch__track {
        height: 1.65rem;
        border-radius: var(--switch-border-radius);
      }

      .mdc-switch__handle-track {
        margin-left: 0.25rem;
        width: calc(100% - var(--mdc-switch-handle-width, 28px));
      }

      &:enabled {
        .mdc-switch__track {
          &::after {
            background: var(--app-primary) !important;
          }
        }
      }

      &.mdc-switch--selected {
        &:enabled {
          .mdc-switch__handle {
            &::after {
              background-color: var(--app-white) !important;
            }
          }

          .mdc-switch__icon {
            fill: var(--app-white) !important;
          }
        }
      }

      &.mdc-switch--unselected {
        .mdc-switch__handle {
          &::after {
            background-color: var(--app-white) !important;
          }
        }

        &:enabled {
          .mdc-switch__icon {
            background: var(--app-white) !important;
            border-radius: 50% !important;
          }
        }
      }
    }

    // END Template Slider
  }
}

.mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-focus-state-layer-color: #ffb300;
  --mdc-switch-selected-handle-color: var(--app-primary) !important;
  --mdc-switch-selected-hover-state-layer-color: #ffb300;
  --mdc-switch-selected-pressed-state-layer-color: #ffb300;
  --mdc-switch-selected-focus-handle-color: var(--app-primary) !important;
  --mdc-switch-selected-hover-handle-color: var(--app-primary) !important;
  --mdc-switch-selected-pressed-handle-color: var(--app-primary) !important;

  --mdc-switch-selected-focus-track-color: rgba(var(--app-primary-rgb), 0.4) !important;
  --mdc-switch-selected-hover-track-color: rgba(var(--app-primary-rgb), 0.4) !important;
  --mdc-switch-selected-pressed-track-color: rgba(var(--app-primary-rgb), 0.4) !important;
  --mdc-switch-selected-track-color: rgba(var(--app-primary-rgb), 0.4) !important;
}
.mdc-form-field {
  display: flex !important;
  align-items: center !important;
  /* vertical-align: middle; */
  justify-content: space-between !important;
  width: 100% !important;
}

.form-check .form-check-input {
  margin-top: 0;
  margin-left: -1.25em !important;
}

// END Toggle Slider CSS

.setup_form_sec,
.create_form_sec {
  // padding: 30px;
  padding: 10px 30px;
}

.whatsapp_preview {
  background: var(--app-text-active);
  border-radius: 0 var(--border-radius) var(--border-radius) 0;
  padding: 20px;
  height: 100%;
  position: relative;
}

// Header SCSS
.header_select {
  .ng-select-container {
    height: var(--fc-height) !important;
  }
}

.navbar-dropdown-account-wrapper[aria-expanded='true']
{
  .c_name::after {
    border-color: transparent transparent #999;
    border-width: 0px 5px 5px;
    top: -2px;
  }
}

.c_name::after {
  content: "";
  border: 2px solid;
  border-color: #999 transparent transparent;
  border-width: 5px 5px 2.5px;
  position: relative;
  display: inline-block;
  top: 1px;
  margin-left: 10px;
}


#accountNavbarDropdown {
  padding: 12px 20px;

  .act_managed {
    padding: 10px;
    border-radius: var(--fc-border-radius);
    background: rgba(var(--app-primary-rgb), 0.15);
    font-size: 12px;
    line-height: 1.4;
    text-align: center;
  }

  .profile_details {
    display: flex;
    align-items: center;
    margin: 10px auto;
  }

  .role_status {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--fc-select-bg);
    border-radius: var(--fc-border-radius);
    padding: 5px 10px;

    .role_name{
      display: flex;
      align-items: center;
      gap: 0.5em;
    }
  }
}
// END Header SCSS

.back_to_login {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
  font-weight: 700;
}

.filter-dropdown {
  .btn {
    &:active {
      border-color: var(--app-primary) !important;
    }
  }

  .dropdown-menu {
    min-width: 300px;
    padding: 0px !important;

    .card {
      border: var(--fc-border) !important;
    }

    .card-body {
      ng-select:not[last-child] {
        margin-bottom: 10px;
      }
    }
  }
}

.selected_filters {
  background: var(--app-gray-100);
  margin-top: 10px;
  border-radius: 10px;
}


.mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled) {
  background-color: var(--app-background) !important;
  height: 20px;
}

.mat-mdc-chip.mdc-evolution-chip--with-trailing-action .mat-mdc-chip-action-label {
  font-size: 11px !important;
}

.mat-mdc-standard-chip.mdc-evolution-chip--with-trailing-action .mdc-evolution-chip__action--trailing {
  padding-left: 8px;
  padding-right: 1px;
}

.clear_filters {
  .mat-mdc-chip {
    background-color: var(--app-primary) !important;
    // background-color: var(--app-gray-300) !important;
    transition: all 0.2s ease-in-out;

    &:hover {
      background-color: var(--app-secondary) !important;
    }

    .mdc-evolution-chip__action--presentational {
      cursor: pointer !important;
    }

    .mat-mdc-chip-action-label {
      color: var(--app-white) !important;
    }
  }
}
.mat-mdc-standard-chip.mdc-evolution-chip--with-trailing-action .mdc-evolution-chip__action--trailing {
  padding-left: 8px;
  padding-right: 1px !important;
  font-family: var(--app-font-family) !important;
  font-size: var(--app-body-font-size) !important;
  font-weight: var(--app-fw-6) !important;
  color: var(--app-text-color-dark) !important;
  letter-spacing: 0px  !important;
  
}
// color: var(--app-text-color-dark);
//   background-color: var(--app-white);
//   border-color: rgba(var(--app-primary-rgb), 0.4);
//   outline: 0;
//   box-shadow: 0 0 10px rgba(var(--app-primary-rgb), 0.1);

.mdc-text-field--no-label:not(.mdc-text-field--textarea) .mat-mdc-form-field-input-control.mdc-text-field__input, 
.mat-mdc-text-field-wrapper .mat-mdc-form-field-input-control{
  font-family: var(--app-font-family) !important;
  font-size: var(--app-body-font-size) !important;
  font-weight: var(--app-fw-6) !important;
  color: var(--app-text-color-dark) !important;
  letter-spacing: 0px  !important;
}

.mdc-text-field--no-label:not(.mdc-text-field--textarea) 
.mat-mdc-form-field-input-control.mdc-text-field__input::placeholder, 
.mat-mdc-text-field-wrapper .mat-mdc-form-field-input-control::placeholder{
  font-size: 0.785rem;
  font-weight: var(--app-fw-6);
  color: var(--fc-input-placeholder);
}

.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: var(--fc-border-color) !important;
  border-radius: var(--fc-border-radius) !important;
}

.mdc-text-field--outlined .mdc-notched-outline {
  background: var(--fc-background);
  z-index: 0 !important;
}

.mdc-notched-outline__leading {
  width: 100%;
}

.mdc-text-field--outlined {
  .mdc-notched-outline {

    .mdc-notched-outline__leading,
    .mdc-notched-outline__trailing {
      width: inherit !important;
    }
  }
}


.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
  // padding-top: 10px !important;
  // padding-bottom: 10px !important;
  padding: 0.5em 1em !important;
  min-height: 42px !important;
  display: flex;
    align-items: center;
}



.mat-mdc-form-field-subscript-wrapper {
  display: none;
}

.mdc-text-field--outlined {
  padding: 0 !important;
  font-size: var(--app-body-font-size);
  font-weight: var(--app-fw-6);
  color: var(--app-text-color-dark);
}

.mat-mdc-text-field-wrapper.mdc-text-field--outlined::placeholder {
  font-size: 0.785rem;
  font-weight: normal;
  color: var(--fc-input-placeholder) !important;
}

// Dashboard CSS
.top_header_options {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 2.6rem;

  .main_title {
    display: inline-flex;
    align-items: center;
    gap: 0.5em;
    margin-bottom: 0px;
  }

  .selection {
    min-width: 200px;
    background: var(--fc-background);

    .ng-select-container {
      min-height: 42px !important;
    }

    .ng-dropdown-panel {
      width: 100% !important;
    }
  }

  #navBtns {
    margin-right: 2rem;
    font-weight: var(--app-fw-7);
    background: var(--app-background-3) !important;

    .nav-link {
      margin: 0.5rem;
      padding: 0.5rem 1rem;
      border-radius: var(--fc-border-radius);
      color: var(--app-dark);

      &.active {
        border: none;
        color: var(--app-white);
        background: #283744 !important;
        border-color: transparent;
      }
    }
  }
}

.nav-link {
  color: var(--app-black);
}

.nav-link:hover,
.nav-link:focus {
  color: var(--app-primary);
}

.top_header_stats_options {
  border-bottom: 1px solid var(--app-light);
  padding-bottom: 1em;
  margin-bottom: 1em;
}

.stats_sec {
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1em;

  .stats_item {
    border: var(--card-border);
    border-radius: var(--card-border-radius);
    padding: 10px;
    min-width: 100px;
    background: var(--app-white);
    box-shadow: var(--card-box-shadow);
    flex: 1 0 0;

    .stats_info_icon {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
    }

    .stats_icon {
      padding: 5px 10px;
      aspect-ratio: 1 / 1;
      border-radius: var(--card-border-radius);
      display: grid;
      place-items: center;
      font-size: var(--app-body-font-size);
      // margin-bottom: 10px;

      &::before {
        font-weight: 600 !important;
      }

      &.submitted {
        color: rgba(0, 168, 186, 1);
        background: rgba(0, 168, 186, 0.1);
      }

      &.opt-out {
        color: rgba(var(--app-warning-rgb));
        background: rgba(var(--app-warning-rgb), 0.1);
      }

      &.sent {
        color: rgba(101, 117, 138, 1);
        background: rgba(101, 117, 138, 0.1);
      }

      &.delivered {
        color: rgba(101, 117, 138, 1);
        background: rgba(237, 251, 236, 1);
      }

      &.read {
        color: rgba(17, 132, 211, 1);
        background: rgba(232, 243, 251, 1);
      }

      &.unread {
        color: rgba(0, 209, 189, 1);
        background: rgba(0, 209, 189, 0.1);
      }

      &.pending {
        color: rgba(212, 99, 240, 1);
        background: rgba(251, 240, 254, 1);
      }

      &.failed {
        color: var(--app-danger);
        background: rgba(var(--app-danger-rgb), 0.1);
      }
    }

    .stats_title {
      color: var(--app-gray-light);
      font-size: 0.75rem;
      font-weight: 600;
    }

    .lead {
      color: var(--app-text-color-dark-2);
      font-size: 1.5rem;
      font-weight: 800;
      line-height: 1;
    }
  }
}

// Status Indicators CSS
.status_indicator {
  display: flex;
  align-items: center;
  gap: 0.4em;
  width: fit-content;
  white-space: nowrap;
  padding: 5px 10px;
  border-radius: 20px;
  font-size: 85%;
  font-weight: 700;
  text-transform: capitalize;

  .status_icon {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    display: block;
  }

  &.opt-out,
  &.inprogress {
    background: rgba(var(--app-warning-rgb), 0.1);

    .status_icon {
      background: var(--app-warning) !important;
    }

    .status_text {
      color: var(--app-warning) !important;
    }
  }

  // &.pause {
  //   background: rgba(var(--app-blue-rgb), 0.1);
  //   .status_icon {
  //     background: var(--app-blue) !important;
  //   }
  //   .status_text {
  //     color: var(--app-blue) !important;
  //   }
  // }

  &.scheduled {
    background: rgba(var(--app-primary-rgb), 0.1);

    .status_icon {
      background: var(--app-primary) !important;
    }

    .status_text {
      color: var(--app-primary) !important;
    }
  }

  &.ongoing,
  &.submitted {
    background: rgba(var(--app-info-rgb), 0.1);

    .status_icon {
      background: var(--app-info) !important;
    }

    .status_text {
      color: var(--app-info) !important;
    }
  }

  &.success,
  &.sent,
  &.approved,
  &.delivered,
  &.completed {
    background: rgba(var(--app-success-rgb), 0.1);

    .status_icon {
      background: var(--app-success) !important;
    }

    .status_text {
      color: var(--app-success) !important;
    }
  }

  &.failed,
  &.rejected {
    background: rgba(var(--app-danger-rgb), 0.1);

    .status_icon {
      background: var(--app-danger) !important;
    }

    .status_text {
      color: var(--app-danger) !important;
    }
  }

  &.preprocessing {
    background: rgba(var(--app-warning-rgb), 0.1);

    .status_icon {
      background: var(--app-warning) !important;
    }

    .status_text {
      color: var(--app-warning) !important;
    }
  }
 
  &.preprocessed,
  &.paused,
  &.pause,
  &.pending
  {
    background: rgba(var(--app-primary-rgb), 0.1);

    .status_icon {
      background: var(--app-primary) !important;
    }

    .status_text {
      color: var(--app-primary) !important;
    }
  }

  &.drafted,
  &.draft {
    background: rgba(var(--app-blue-rgb), 0.1);

    .status_icon {
      background: var(--app-blue) !important;
    }

    .status_text {
      color: var(--app-blue) !important;
    }
  }

  &.read {
    background: rgba(var(--app-deep-blue-rgb), 0.1);

    .status_icon {
      background: var(--app-deep-blue) !important;
    }

    .status_text {
      color: var(--app-deep-blue) !important;
    }
  }
}
// END Status Indicators CSS

.stats_graph {
  display: flex;
  justify-content: center;

  .chart {
    border-radius: var(--card-border-radius);
    max-height: 600px;
    height: fit-content !important;
    padding: 1.5rem 1rem;
    background: var(--fc-select-bg);
  }
}

// END Dashboard CSS

// Search Bar
.search_bar {
  .form-control {
    min-height: 42px !important;
    box-shadow: none;
    border-top: none;
    border-left: none;
    border-right: none;
    border-radius: 0 !important;
    background: transparent !important;
  }
}
// END Search Bar

.mat-mdc-menu-panel {
  // max-width: 100% !important;
  min-width: 260px !important;
  max-width: 300px !important;
}

.mat-mdc-menu-item.mdc-list-item
{
  border-radius: var(--fc-border-radius);
}

.mat-mdc-menu-item:not([disabled]):hover {
  background-color: rgba(var(--app-primary-rgb), 0.15) !important;
  border-radius: var(--fc-border-radius) !important;
}

.cst_notifications a .bell_animate {
  animation: clapp 1s linear infinite;
}

// Table Presentation CSS
.table-wrapper {
  overflow: hidden;
  overflow-x: auto !important;
  border-top: 1px solid var(--app-light);
  border-radius: var(--table-border-radius);

  .table {
    thead {
      background-color: var(--table-head-bg);

      th {
        color: var(--app-white);
      }

      th:last-child {
        // border-top-right-radius: 10px;
        text-align: center !important;
      }

      .mdc-checkbox .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate=true])~.mdc-checkbox__background {
        border-color: var(--app-white);
      }

      .mdc-checkbox .mdc-checkbox__native-control:enabled~.mdc-checkbox__background .mdc-checkbox__mixedmark {
        border-color: var(--app-white) !important;
      }
    }

    .table-row {
      border-bottom: 1px solid var(--app-light) !important;
      font-weight: 400;

      th,
      td {
        background: inherit;
        vertical-align: middle !important;
        // padding: 1.25rem;
      }
      th:first-child {
        display: flex;
        align-items: center;
        border-bottom: 0px;
      }

      .cmp_select {
        display: flex;
        align-items: center;
      }
    }

    .table-body {
      border: var(--fc-border);

      .table-row {
        &:hover {
          cursor: pointer;
          background: var(--app-gray-100) !important;
        }

        td {
          border: none;
          box-shadow: none;

          &:last-child {
            text-align: center;
          }
        }

        .action_options {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
}
// END Table Presentation CSS

// Checkbox
.list-checkboxes {
  position: relative;
  bottom: 1px;
  margin-left: -0.65rem !important;  

  &.mat-mdc-checkbox {
    .mdc-form-field {
      height: 1rem !important;

      .mdc-checkbox__ripple,
      .mat-mdc-checkbox-ripple {
        display: none !important;
      }

      .mdc-checkbox {
        .mdc-checkbox__background {
          background: var(--app-white);
          border-width: 1px;

          .mdc-checkbox__checkmark {
            height: 12px;
            margin-top: 2px;
            color: var(--app-white) !important;
          }
        }

        .mdc-checkbox__native-control {
          &:enabled {
            &:checked~ {
              .mdc-checkbox__background {
                border-color: var(--app-primary);
                background: var(--app-primary);
              }
            }
          }
        }
      }
    }
  }
}

// END Checkbox

.model_icon {
  font-size: 3em;
}

.modal .modal-dialog .modal-content {
  .warning_text {
    // width: 100%;
    // display: flex;
    // justify-content: center;
    // align-items: center;
    // gap: 0.5em;
    background: rgba(var(--app-warning-rgb), 0.15);
    padding: 1rem;
    border-radius: var(--fc-border-radius);
    border: 1px solid var(--app-warning);
  }
}

.mat-mdc-chip-remove .mat-icon {
  line-height: 1.3;
}

// Matradio Button CSS
.mat-mdc-radio-button .mdc-form-field {
  color: var(--app-text-color-dark) !important;
  font-size: 0.75rem !important;
  font-weight: var(--app-fw-6) !important;
  font-family: var(--app-font-family) !important;
  letter-spacing: var(--app-body-letter-spacing) !important;
}

.mdc-form-field {
  label {
    margin: 0;
    margin-right: 0px !important;
    font-size: 0.875rem !important;
    font-weight: 700 !important;
  font-family: var(--app-font-family) !important;
  letter-spacing: 0;
  order: 0;
  }
}

.mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled+.mdc-radio__background .mdc-radio__inner-circle {
  border-color: var(--app-primary) !important;
}

.mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled:not(:checked)+.mdc-radio__background .mdc-radio__outer-circle,
.mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled:checked+.mdc-radio__background .mdc-radio__outer-circle {
  border-color: var(--app-secondary) !important;
}

.mat-mdc-radio-button.mat-mdc-radio-checked .mdc-radio__background::before {
  background-color: var(--app-secondary) !important;
}

// END Matradio Button CSS

// File Upload Drop Zone CSS
.upload_dropZone {
  background: var(--fc-background);
}

.media_upload_dropZone {
  background-color: var(--app-white);
  border: 1px dashed var(--app-label);
  border-radius: var(--border-radius);
  padding: 10px;
  position: relative;

  &:hover {
    border-color: var(--app-primary);
  }

  .upload_image {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
  }
}

.upload_gallery {
  display: flex;
  align-items: center;

  .uploaded_image {
    position: relative;
    width: 8%;

    .img_close {
      cursor: pointer;
      position: absolute;
      top: -4px;
      left: -4px;
      color: var(--app-danger);
      font-size: 1em;

      &:hover {
        color: var(--app-danger);
        transform: scale(1.4);
      }
    }
  }
}
// END File Upload Drop Zone CSS


// Media Drive CSS
.media_seleted {
  border-color: var(--app-primary);
}

.media_card_header {
  position: absolute;
  width: 100%;
  background: transparent;
}

.preview_min_height
{
  min-height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.image_wrapper {
  width: 100%;
  height: 100px;

  .media_avatar_img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
  }
}

.media_avatar {
  position: relative;
  display: inline-block;
  width: 3rem;
  height: auto;
  aspect-ratio: 1/1;
}
// END Media Drive CSS

.success_screen {
  display: grid;
  place-items: center;
  height: inherit;
  height: 100%;
  max-height: calc(100% - 120px);

  // .success_img {
  //   // animation: rotate-circle 5s ease-in-out infinite;
  //   // animation: popup 3s ease-in-out infinite;
  // }
}

.campaign_view_details {
  .stats_sec {
    .stats_item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 0.5em;
      flex: 1 0 160px !important;

      .stats_info_icon {
        margin: 0;
      }
    }
  }
}

.card_align_items {
  .card {
    .card-footer {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      .card_ftr_cancel {
        padding: 0.5rem 0.875rem !important;
        line-height: 1.6em !important;
      }

      .card_ftr_cancel:focus {
        background-color: var(--nlu-secondary);
        border-color: var(--nlu-secondary);
        color: var(--app-white);
        // border-color: rgba(55, 125, 255, 0.4);
      }
    }
  }
}

.form_action_btns {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2em;

  .options_left {
    display: flex;
    align-items: center;
    gap: 1em;
  }

  .options_right {
    display: flex;
    align-items: center;
    gap: 1em;
  }

  .btn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5em;
    min-width: 120px;
  }
}

.mat-mdc-menu-item {
  padding: 5px;
  margin: 5px;
  min-height: 32px;

  .mdc-list-item__primary-text span {
    display: flex;
    align-items: center;
    gap: 0.5em;
  }
}

// Mat Stepper CSS
.mat-horizontal-stepper-header-container {
  border-bottom: 2px solid var(--app-gray-200);
  margin-bottom: 10px;
  padding-bottom: 10px;
}

.mat-horizontal-stepper-header {
  height: 40px !important;
  border-radius: var(--fc-border-radius) !important;
}

.mat-stepper-horizontal-line {
  margin: auto !important;
  border-top: 2px solid var(--app-gray-400) !important;
}

.mat-step-icon-selected,
.mat-step-icon-state-edit,
.mat-step-icon-state-done {
  background-color: var(--app-primary) !important;
  color: var(--app-white) !important;
}

// END Mat Stepper CSS


// Button With loading spinner
.button_text {
  color: #fff;
  transition: all 0.2s;
}

.btn-loading .button_text {
  visibility: hidden;
  opacity: 0;
}

.btn-loading::after {
  content: "";
  position: absolute;
  width: 25px;
  height: 25px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  border: 2px solid transparent;
  border-top-color: var(--app-white)fff;
  border-radius: 50%;
  animation: button-loading-spinner 1s ease infinite;
}

@keyframes button-loading-spinner {
  from {
    transform: rotate(0turn);
  }

  to {
    transform: rotate(1turn);
  }
}

// END Button With loading spinner


// Animations
// @keyframes ringing {
//   0%, 100% {
//     transform: rotate(0)
//   }

//   20% {
//     transform: rotate(10deg)
//   }

//   40%, 80% {
//     transform: rotate(-6deg);
//   }

//   60% {
//     transform: rotate(6deg);
//   }
// }

@keyframes clapp {
  $_x: 0px;

  0%,
  100% {
    transform: translate($_x, 0px) rotate(0);
  }

  20% {
    transform: translate($_x + 4px, 0px) rotate(-10deg);
  }

  40% {
    transform: translate($_x - 4px, 0px) rotate(10deg);
  }

  60% {
    transform: translate($_x + 3px, 0px) rotate(-8deg);
  }

  80% {
    transform: translate($_x - 3px, 0px) rotate(8deg);
  }
}

// @keyframes popup {
//   // 0% {transform: scale(0); opacity: 0;}
//   // 20% {transform: scale(0.2); opacity: 0.2;}
//   // 40% {transform: scale(0.2); opacity: 0.4;}
//   // 60% {transform: scale(0.5); opacity: 0.6;}
//   80% {transform: scale(0.7); opacity: 0.8;}
//   100% {transform: scale(1); opacity: 1;}
// }

// @keyframes rotate-circle {
//   0% {
//       transform: rotate(0deg);
//   }
//   20% {
//       transform: rotate(45deg);
//   }
//   40% {
//       transform: rotate(90deg);
//   }
//   60% {
//       transform: rotate(180deg);
//   }
//   80% {
//       transform: rotate(270deg);
//   }
//   100% {
//       transform: rotate(360deg);
//   }
// }
// END Animations

// Analyze Loader CSS
.analyze_loader {
  font-size: 18px;
  color: var(--app-secondary);
  display: inline-block;
  font-weight: 400;
  position: relative;
}

.analyze_loader:before {
  content: "";
  height: 5px;
  width: 100%;
  display: block;
  background: var(--app-default);
  position: absolute;
  bottom: 0;
  z-index: 0;
}

.analyze_loader:after {
  content: "";
  height: 5px;
  width: 0%;
  display: block;
  background: var(--app-primary);
  animation: 5s lineGrow linear infinite;
  z-index: 1;
  position: relative;
}

@keyframes lineGrow {
  to {
    width: 100%;
  }
}

// END Analyze Loader CSS

/*!
 * Bootstrap Image Checkbox v0.1.0 (https://iqbalfn.github.io/bootstrap-image-checkbox/)
 */
.custom-control.image-checkbox {
  position: relative;
  padding-left: 0;
}

.custom-control.image-checkbox .custom-control-input:checked~.custom-control-label:after,
.custom-control.image-checkbox .custom-control-input:checked~.custom-control-label:before {
  opacity: 1;
}

.custom-control.image-checkbox label {
  cursor: pointer;
}

.custom-control.image-checkbox label:before {
  border-color: var(--app-primary);
  background-color: var(--app-primary);
}

.custom-control.image-checkbox label:after,
.custom-control.image-checkbox label:before {
  transition: opacity 0.3s ease;
  opacity: 0;
  left: 0.25rem;
}

.custom-control.image-checkbox label:focus,
.custom-control.image-checkbox label:hover {
  opacity: 0.8;
}

.custom-control.image-checkbox label img {
  border-radius: 2.5px;
}

.form-group-image-checkbox.is-invalid label {
  color: var(--app-danger);
}

.form-group-image-checkbox.is-invalid .invalid-feedback {
  display: block;
}


.p-element {
  .p-calendar {
    .p-inputtext {
      min-height: 48px !important;
      min-width: 150px;
      background: var(--fc-select-bg);
      font-weight: var(--app-fw-6);
      border-radius: var(--fc-border-radius) !important;
      border: var(--fc-border) !important;
      outline: none !important;
      appearance: none !important;
      padding: 10px;
    }

    .p-button-icon-only {
      background: var(--app-primary);
      border: var(--app-primary);
      border-top-right-radius: var(--fc-border-radius);
      border-bottom-right-radius: var(--fc-border-radius);

      .p-icon-wrapper {
        color: var(--app-white);

        svg {
          filter: none;
        }
      }
    }
  }
}

.p-datepicker {
  .p-datepicker-group-container {
    padding: 10px;

    .p-datepicker-header {
      padding-bottom: 10px;
    }

    .p-datepicker-calendar {
      margin-top: 10px;
    }
  }
}

.p-timepicker {
  button {
    color: var(--app-primary);
  }
}

.p-datepicker-buttonbar {
  padding: 10px;
}


.mat-badge-content {
  background: var(--app-primary) !important;
}

// th:first-child, td:first-child
// {
//   position:sticky;
//   left:0px;
//   // background-color:var(--app-white)!important;
//   z-index: 1;
//   box-shadow: 5px 5px 5px #000 !important;
// }

.accordion-button {
  font-size: var(--app-body-font-size);
  font-weight: var(--app-fw-6);
  color: var(--app-text-color-dark);
}
.accordion-button::after {
  flex-shrink: 0;
  width: var(--app-body-font-size);
  height: var(--app-body-font-size);
  margin-left: auto;
  content: "";
  background-image: var(--bs-accordion-btn-icon);
  background-repeat: no-repeat;
  background-size: var(--app-body-font-size);
  transition: var(--bs-accordion-btn-icon-transition);
}
.accordion-button:not(.collapsed) {
  color: var(--app-text-color-dark);
  background-color: var(--app-light);
  box-shadow: none;
}
.accordion-body {
  padding: var(--bs-accordion-body-padding-y) var(--bs-accordion-body-padding-x);
  background-color: var(--fc-background);
}
.accordion-button:not(.collapsed)::after {
  background-image: var(--bs-accordion-btn-active-icon);
  transform: var(--bs-accordion-btn-icon-transform);
}

.accordion-button:not(.collapsed)::after svg {
  fill: var(--app-white);
}

.selected_chips_group {
  display: flex;
  align-items: center;
  gap: 0.5em;
}

.list_selection_panel
{
  padding: 10px;
  max-height: 200px;
}

mat-header-row{
  min-height: 40px !important;
}

.sava_as_btn {
  min-width: 160px;
}

.more_btn,
.less_btn
{
  min-width: 100px;
}